import React, { useState } from "react";
import { motion } from "framer-motion";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Typography,
  Input,
  Table,
  Modal,
  Popconfirm,
  message,
  InputNumber,
  Select,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/auth";
import api from "../../api";
import connectCSKH from "../../utils/connectCSKH";
import "./Vay.scss";
import { months } from "moment";
import { MONEY_LOAN, LOAN_RATE } from "../../utils/constant";
import { currencyFormatVND } from "../../utils/CurrencyFormat";
// import CurrencyInput from 'react-currency-input-field';

const { Option } = Select;

export default function Vay() {
  const [verify, setVerify] = useState("confirm");
  const monthData = [6, 12, 18, 24, 36, 48, 60];
  const dataCall = [
    {
      value: "Thời gian nhận cuộc gọi bất cứ lúc nào",
      label: "Thời gian nhận cuộc gọi bất cứ lúc nào",
    },
    {
      value: "Nhận cuộc gọi vào buổi sáng",
      label: "Nhận cuộc gọi vào buổi sáng",
    },
    {
      value: "Nhận cuộc gọi vào buổi trưa",
      label: "Nhận cuộc gọi vào buổi trưa",
    },
    {
      value: "Nhận cuộc gọi vào buổi chiều",
      label: "Nhận cuộc gọi vào buổi chiều",
    },
  ];
  const [selectTimeToCall, setSelectTimeToCall] = useState(dataCall[0].value);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [amount, setAmount] = useState(0);
  const [month, setMonth] = useState(6);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [notModal, setNotModal] = useState(false);
  const [one, setOne] = useState(true);
  const [two, setTwo] = useState(false);
  const [three, setThree] = useState(false);
  const [four, setFour] = useState(false);
  const [five, setFive] = useState(false);
  const [six, setSix] = useState(false);
  const [seven, setSeven] = useState(false);
  const interestRate = Math.round(amount * 0.02 * month);
  const payback = amount + interestRate;
  const loadProfile = async () => {
    const { data } = await api.get("/users/profile");
    dispatch(actions.initialLogin(data.data));
    dispatch({ type: "UPDATE_KYC", payload: { ...(data?.data?.kyc || {}) } });
  };
  const onConfirm = async () => {
    setSubmitLoading(true)
    const { data } = await api.get("/contracts");
    if (data.data.length > 0) {
      setSubmitLoading(false)
      setNotModal(true);
      return;
    }
    if (isNaN(amount) || amount < 40000000 || amount > 300000000) {
      setSubmitLoading(false)
      message.error(`Hạn mức vay trong khoảng 40tr đến 300tr đồng.`);
      return;
    }
    dispatch({
      type: "DISPATCH_CONTRACT",
      payload: { contract: { times: month, amount } },
    });
    if (profile?.verifiedPersonalInfo && profile?.verifiedContactInfo && profile?.verifiedBankInfo) {
      await api.post('/contracts/createContractImage', {
        times: month,
        amount: amount,
      });
      await api.post("/users/updateProfile", {
        selectTimeToCall,
      });
      await loadProfile();
      setSubmitLoading(false)
      history.replace("/contracts");
    } else {
      setSubmitLoading(false)
      history.push("/verify");
    }
  };

  const handleMonth = (value) => {
    setMonth(value);
    setOne(false);
    setTwo(false);
    setThree(false);
    setFour(false);
    setFive(false);
    setSix(false);
    setSeven(false);

    if (value === 6) {
      setOne(true);
    }
    if (value === 12) {
      setTwo(true);
    }
    if (value === 18) {
      setThree(true);
    }
    if (value === 24) {
      setFour(true);
    }
    if (value === 36) {
      setFive(true);
    }
    if (value === 48) {
      setSix(true);
    }
    if (value === 60) {
      setSeven(true)
    }
  };

  const handleChange = (value) => {
    setSelectTimeToCall(value);
  };

  return (
    <div className="container">
      <div className="header-container">
        <div />
        <Typography.Text
          strong
          style={{ fontWeight: 700, fontSize: 18, color: "#fff" }}
        >
          Vay tiền
        </Typography.Text>
        <div></div>
      </div>

      <div className="vay-body">
        <div className="title-container">
          <Typography.Text className="title">
            Số tiền muốn vay (VNĐ)
          </Typography.Text>
        </div>

        <div className="note-container">
          <Typography.Text className="note">
            Hạn mức vay từ 40 triệu đến 300 triệu VNĐ
          </Typography.Text>
        </div>

        <div className="input-container ">
          {/* <Select
            virtual={false}
            dropdownClassName="select-amount"
            style={{ borderRadius: 20, width: "100%" }}
            value={amount}
            options={MONEY_LOAN.map((val) => ({
              label: val.toLocaleString(),
              value: val,
            }))}
            onChange={(value) => setAmount(parseInt(value))}
          /> */}
          {/* <InputNumber 
            style={{ borderRadius: 20, width: "100%" }}
            value={amount}
            placeholder="Nhập số tiền muốn vay bằng số"
            onChange={(value) => setAmount(parseInt(value))}
            /> */}
          <Input
            className="input-field"
            type="number"
            placeholder="Nhập số tiền muốn vay bằng số"
            style={{ minWidth: "100%" }}
            onChange={(value) => setAmount(value.target.value)}
          />
          {/* <Input
            value={amount}
            className=""
            min={0}
            max={1000000000}
            placeholder="Nhập số tiền bạn cần vay"
            onChange={(e) => setAmount(parseInt(e.target.value))}
          /> */}
        </div>
        <div className="moneyShow-container">
          <Typography.Text className="moneyShow">
            {amount === NaN ? 0 : currencyFormatVND(amount)} VNĐ
          </Typography.Text>
        </div>
        <div className="month-container" style={{ padding: 10 }}>
          {/* <Typography.Text>Chọn thời hạn vay</Typography.Text> */}
          <span
            className={`${one === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(6)}
          >
            6 tháng
          </span>
          <span
            className={`${two === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(12)}
          >
            12 tháng
          </span>
          <span
            className={`${three === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(18)}
          >
            18 tháng
          </span>
          <span
            className={`${four === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(24)}
          >
            24 tháng
          </span>
          <span
            className={`${five === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(36)}
          >
            36 tháng
          </span>
          <span
            className={`${six === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(48)}
          >
            48 tháng
          </span>
          <span
            className={`${seven === true ? `month_bg` : ""} month_style`}
            onClick={() => handleMonth(60)}
          >
            60 tháng
          </span>
        </div>
        <div className="select_container">
          <Select
            onChange={handleChange}
            value={selectTimeToCall}
            placeholder="Thời gian nhận cuộc gọi bất cứ lúc nào"
            style={{ borderRadius: 20, minWidth: "100%" }}
            name="contactMethod"
            options={dataCall.map((value) => ({
              label: value.label,
              value: value.value,
            }))}
          />
          {/* {dataCall.map((value) => (
              <Select.Option key={value} value={value.value} className="month-picker">
                {value.label}
              </Select.Option>
            ))} */}
        </div>
      </div>
      {/* <div className="form-container">
        <div className="information-form">
          <Typography.Text
            className="form-title"
            style={{ color: "#fff", fontWeight: "bold" }}
          >
            Thông tin khoản vay
          </Typography.Text>
          <div className="information">
            <div className="details-information">
              <Typography.Text>Số tiền</Typography.Text>
              <Typography.Text>{amount?.toLocaleString()} đ</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Thời hạn vay</Typography.Text>
              <Typography.Text>{month} tháng</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Ngày vay</Typography.Text>
              <Typography.Text>{date}</Typography.Text>
            </div>
            <div className="details-information">
              <Typography.Text>Hình thức thanh toán</Typography.Text>
              <Typography.Text>Trả góp mỗi tháng</Typography.Text>
            </div>
          </div>
        </div>
      </div> */}
      <div className="interest">
        <div className="old-debt-text">
          <Typography.Text
            style={{ flex: 2, color: "#666", fontSize: 14, fontWeight: "bold" }}
          >
            Hàng tháng phải trả
          </Typography.Text>
        </div>
        <div className="old-debt-text">
        <Typography.Text style={{ flex: 2, color: "#3e3e3e", fontSize: 18, fontWeight : 'bold' }}>
            {currencyFormatVND(Math.round((amount) * LOAN_RATE / 100+ (amount / month )))} VNĐ
          </Typography.Text>
        </div>
        <div className="old-debt-text">
          <Typography.Text
            style={{ flex: 2, color: "#666", fontSize: 14, fontWeight: "bold" }}
          >
            Lãi suất hàng tháng
          </Typography.Text>
          <Typography.Text
            style={{
              flex: 2,
              color: "#3e3e3e",
              fontSize: 16,
              fontWeight: "bold",
            }}
          >
            {LOAN_RATE}%
          </Typography.Text>
        </div>
        {/* <div className="old-debt-text">
          <Typography.Link onClick={() => setShowDetail(true)}>
            Chi tiết trả nợ
          </Typography.Link>
        </div> */}
      </div>
      <div className="btn-container">
        {/* <Popconfirm
          title={`Đồng ý vay ${
            currencyFormatVND(amount) || 0
          } VNĐ kỳ hạn ${month} tháng ?`}
          onConfirm={onConfirm}
        >
          <Button className="confirm-btn">
            <Typography.Text className="btn-title">Vay ngay</Typography.Text>
          </Button>
        </Popconfirm> */}
        <Button className="confirm-btn" onClick={onConfirm} loading={submitLoading}>
            <Typography.Text className="btn-title">Vay ngay</Typography.Text>
        </Button>
      </div>

      <ModalDetailPayment
        visible={showDetail}
        onCancel={() => setShowDetail(false)}
        times={month}
        amount={amount}
      />
      <>
        <Modal
          title={null}
          footer={null}
          visible={notModal}
          closable={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#FAD400",
            }}
          >
            <Typography.Text style={{ color: "#fff", fontSize: 17 }} strong>
              Thông báo
            </Typography.Text>
          </div>
          <br />
          <Typography.Text
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Bạn đã có khoản vay. Vui lòng không đăng ký lại !
          </Typography.Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: 30,
            }}
          >
            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              style={{
                background: "#888",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                padding: "0px 10px",
              }}
              onClick={() => setNotModal(false)}
            >
              <Typography.Text
                style={{ fontSize: 14, color: "#fff", fontWeight: "bold" }}
              >
                Đóng
              </Typography.Text>
            </motion.div>

            <motion.div
              whileTap={{ opacity: 0.4, scale: 0.97 }}
              style={{
                background: "#FAD400",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                padding: "0px 10px",
              }}
              onClick={connectCSKH}
            >
              <Typography.Text
                style={{ color: "#fff", fontWeight: 400, fontSize: 14 }}
              >
                Liên hệ CSKH
              </Typography.Text>
            </motion.div>
          </div>
        </Modal>
      </>
    </div>
  );
}
const ModalDetailPayment = ({ visible, onCancel, times, amount }) => {
  const array = [...new Array(times)].map((item, index) => ({
    index: index + 1,
    amount: amount / times + amount * LOAN_RATE / 100,
    period: `${moment()
      .add(index + 1, "month")
      .format("DD-MM-YYYY")}`,
  }));
  const columns = [
    {
      title: "Kỳ",
      dataIndex: "index",
      key: "index",
      render: (text) => <Typography.Text>Kỳ thứ {text}</Typography.Text>,
    },
    {
      title: "Số tiền",
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <Typography.Text strong>
          {currencyFormatVND(Math.ceil(text))}
        </Typography.Text>
      ),
    },
    {
      title: "Ngày đóng",
      dataIndex: "period",
      key: "period",
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
  ];
  return (
    <Modal
      visible={visible}
      onOk={onCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      closeIcon={() => <></>}
    >
      <div style={{ maxHeight: 350, overflowY: "scroll" }}>
        <Table dataSource={array} columns={columns} pagination={false} />
      </div>
    </Modal>
  );
};
