import React, { useEffect, useState } from "react";
import { CreditCard } from "../../components";
import { motion } from "framer-motion";
import { Input, Select, Typography, Image, message, Button } from "antd";
import _bank from "./bank.json";
import { isNotEmptyString } from "../../utils/isEmptyString";

export default function Three({ handleCallApi, profile, isVerified }) {
  const [state, setState] = useState({
    bankName: "",
    logo: "",
    number: "",
    name: "",
    idCard: "",
  });
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setState({
      bankName: profile?.bank?.bankName,
      logo: profile?.bank?.logo,
      number: profile?.bank?.number,
      name: profile?.bank?.name,
      idCard: profile?.bank?.idCard,
    });
    if (isVerified) {
      setDisabled(true);
    }
  }, [profile]);

  async function onConfirm() {
    if (!state.bankName || !state.name || !state.number || !state.idCard) { 
      message.error("Vui lòng cung cấp đầy đủ thông tin yêu cầu");
      return;
    }
    let _bankInfo = {
      bank: state,
    };

    const verified = {};
    if (
      Object.values(state).length === 5 &&
      Object.values(state).indexOf("") < 0
    ) {
      verified.verifiedBankInfo = 1;
    }
    handleCallApi(_bankInfo, 3, verified);
  }

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="bank-form-container"
    >
      <CreditCard
        logoBank={state?.logo}
        name={state?.name}
        bankNumber={state?.number}
        nameBank={state?.bankName}
        bankDate={state?.date}
      />
      <div style={{ padding: 20 }}>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> ID thẻ
        </p>
        <Input
          size="large"
          placeholder="ID thẻ ngân hàng"
          // type="number"
          value={state.idCard}
          onChange={(e) => {
            const inputValue = e.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
            setState({ ...state, idCard: sanitizedValue });
          }}
          // pattern="
          pattern='"[0-9]*'
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.idCard)}
        />

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Tên ngân hàng
        </p>
        <Select
          className="select-bank"
          placeholder="Tên ngân hàng"
          disabled={isNotEmptyString(profile?.bank?.bankName)}
          options={_bank.banksnapas.map((item) => ({
            value: item.shortName,
            label: (
              <>
                <div>
                  <Typography.Text strong> {item.shortName}</Typography.Text> -{" "}
                  <Typography.Text>{item.vn_name}</Typography.Text>
                </div>
                {item.logo && (
                  <Image src={item.logo} preview={false} width="20%" />
                )}
              </>
            ),
          }))}
          value={state.bankName}
          onChange={(e) => {
            setState({
              ...state,
              bankName: e,
              logo: _bank.banksnapas.find((item) => item.shortName === e)?.logo,
            });
          }}
        ></Select>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Số tài khoản
        </p>
        <Input
          size="large"
          placeholder="Số tài khoản"
          // type="number"
          value={state.number}
          onChange={(e) => {
            const inputValue = e.target.value;
            const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
            setState({ ...state, number: sanitizedValue });
          }}
          // pattern="
          pattern='"[0-9]*'
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.number)}
        />

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Tên chủ thẻ
        </p>
        <Input
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
          size="large"
          placeholder="Tên chủ thẻ"
          className="bank-input"
          disabled={isNotEmptyString(profile?.bank?.name)}
        />

         { isVerified !== 1 ? <div className="send-request-div">
          <Button
            className="confirm-btn"
            onClick={onConfirm}
          >
            <Typography.Text className="confirm-div-title">
              Gửi yêu cầu
            </Typography.Text>
          </Button>
        </div> : '' }
      </div>
    </motion.div>
  );
}
