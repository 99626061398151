/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import { Typography, Spin, message, Form, Input, Button, Select } from "antd";
import axios from "axios";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import Compress from "react-image-file-resizer";
import converter from "../../utils/converterBase64ToBinary";
import "./verify.scss";
import { BASE_URL } from "../../utils/constant";
import InputMask from "react-input-mask";
import api from "../../api";
import moment from "moment";
import { isNotEmptyString } from "../../utils/isEmptyString";

const Camera = ({ type, setImage, image, profile, isVerified }) => {
  const [loading, setLoading] = useState(false);

  function onChange(event) {
    if (event.target && event.target.files[0]) {
      Compress.imageFileResizer(
        event.target.files[0],
        480,
        480,
        "PNG", // compress format WEBP, JPEG, PNG
        100,
        0, // rotation
        async (uri) => {
          try {
            let formData = new FormData();
            const dataURI = converter(uri);
            formData.append("file", dataURI);
            setLoading(true);
            const { data } = await axios.post(BASE_URL + "/upload", formData, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            });

            // const transformater = 'w_300,h_400,c_fit';
            // const splited = data.secure_url.split('/');
            // let str = [
            //   ...splited.slice(0, 6),
            //   transformater,
            //   ...splited.slice(6),
            // ];

            const endpoint = data.secure_url;
            localStorage.setItem(type, endpoint);
            setImage(endpoint);
          } catch (err) {
            message.error("Đã có lỗi trong việc upload ảnh. Vui lòng thử lại");
          } finally {
            setLoading(false);
          }
        },
        "base64" // blob or base64 default base64
      );
    }
  }

  return (
    <motion.div
      className="image-container"
      style={{
        backgroundImage: `url(${image})`,
        position: "relative",
      }}
      whileTap={{ scale: 0.98, opacity: 0.3 }}
      onClick={() => !image && document.getElementById(`camera${type}`).click()}
    >
      {
        image && isVerified !== 1 ?
        <DeleteOutlined
          style={{
            padding: "8px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            position: "absolute",
            top: "8px",
            right: "8px",
            fontSize: "16px",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setImage(null);
          }}
        /> : ''
      }
      {loading ? (
        <Spin spinning />
      ) : (
        <>
          {!image && (
            <CameraOutlined
              style={{ fontSize: 30, color: image ? "#fff" : "#333" }}
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={onChange}
            id={`camera${type}`}
            hidden
          />

          {type == "front" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Mặt trước CMND / CCCD
            </Typography.Text>
          )}
          {type == "back" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              Mặt sau CMND / CCCD
            </Typography.Text>
          )}
          {type == "face" && !image && (
            <Typography.Text
              style={{
                color: image ? "#fff" : "#333",
                fontWeight: 700,
                fontSize: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>Ảnh chân dung</div>
              <div>ảnh CCCD/CMND để trước ngực</div>
            </Typography.Text>
          )}
        </>
      )}
    </motion.div>
  );
};

export default ({ handleCallApi, profile, isVerified }) => {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [face, setFace] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setFront(profile?.id_front);
    setBack(profile?.id_back);
    setFace(profile?.id_with_face);
    form.setFieldsValue({
      address: profile?.address,
      dob: profile?.dob,
      id_number: profile?.id_number,
      name: profile?.name,
      sex: profile?.sex,
      job: profile?.job,
      income: profile?.income,
      purpose: profile?.purpose,
    });
    if (isVerified) {
      setDisabled(true);
    }
  }, [profile]);

  const onFinish = async (values) => {
    if (!front || !back || !face) {
      message.error("Vui lòng cung cấp đủ thông tin");
      return;
    }

    if (
      +moment().format("YYYY") - parseInt(values["dob"].split("/")[2]) <=
      18
    ) {
      message.error("Độ tuổi phải trên 18.");
      return;
    }
    if (profile?.name !== values.id_number) {
      const { data } = await api.get(`/users/checkId?id=${values.id_number}`);
      if (data.data) {
        message.error("Chứng minh thư đã được sử dụng để thực hiện khoản vay.");
        return;
      }
    }
    
    let _personalInfo = {
      ...values,
      id_front: front,
      id_back: back,
      id_with_face: face,
    };

    const verified = {};
    if (
      Object.values(_personalInfo).length === 11 &&
      Object.values(_personalInfo).indexOf("") < 0
    ) {
      verified.verifiedPersonalInfo = 1;
    }

    handleCallApi(_personalInfo, 1, verified);
  };
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="user-img-container"
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%", marginTop: "12px" }}
        onFinish={onFinish}
      >
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Họ tên
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Hãy nhập tên của bạn",
            },
          ]}
          name="name"
        >
          <Input
            size="large"
            placeholder="Nhập họ tên "
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.name)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Ngày sinh
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập ngày sinh của bạn",
            },
          ]}
          name="dob"
        >
          <InputMask
            mask="99/99/9999"
            maskPlaceholder="dd/mm/yyyy"
            placeholder="Ngày/Tháng/Năm"
            className="mask-focus mask-input-date"
            disabled={isNotEmptyString(profile?.dob)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Giới tính
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Chọn giới tính",
            },
          ]}
          name="sex"
        >
          <Select
            size="large"
            placeholder="Giới tính"
            style={{
              borderRadius: 5,
              minWidth: "100%",
              fontSize: 16,
            }}
            disabled={isNotEmptyString(profile?.sex)}
          >
            <Select.Option value="Nam">Nam</Select.Option>
            <Select.Option value="Nữ">Nữ</Select.Option>
            <Select.Option value="Khác">Khác</Select.Option>
          </Select>
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Địa chỉ
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập địa chỉ của bạn",
            },
          ]}
          name="address"
        >
          <Input
            size="large"
            placeholder="Nhập địa chỉ"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.address)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Số CMND/CCCD
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập số CMND/CCCD",
            },
          ]}
          name="id_number"
        >
          <Input
            type="number"
            size="large"
            placeholder="Số CMND/CCCD"
            // suffix={<PicRightOutlined className="information-icon" />}
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.id_number)}
          />
        </Form.Item>
        
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Nghề nghiệp
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập công việc hiện tại ",
            },
          ]}
          name="job"
        >
          <Input
            size="large"
            placeholder="Nghề nghiệp"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.job)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Thu nhập
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập thu nhập của bạn",
            },
          ]}
          name="income"
        >
          <Input
            size="large"
            placeholder="Thu nhập hàng tháng"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.income)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Mục đích vay tiền
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập mục đích vay của bạn",
            },
          ]}
          name="purpose"
        >
          <Input
            size="large"
            placeholder="Mục đích vay tiền"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.purpose)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px", textAlign: "left" }}>
          <span style={{ color: "red" }}>*</span> Hình ảnh CMND/CCCD
        </p>
        <Camera
          type="front"
          setImage={setFront}
          image={front}
          profile={profile}
          isVerified={isVerified}
        />
        <Camera type="back" setImage={setBack} image={back} profile={profile} isVerified={isVerified}/>
        <Camera type="face" setImage={setFace} image={face} profile={profile} isVerified={isVerified}/>

       {isVerified !== 1 ? <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          className="confirm-div"
        >
          <Button
            htmlType="submit"
            style={{ background: "transparent", border: "none" }}
          >
            <Typography.Text
              style={{ color: "#fff", fontWeight: 700, fontSize: 17 }}
            >
              Tiếp tục
            </Typography.Text>
          </Button>
        </motion.div> : <></> }
      </Form>
    </motion.div>
  );
};
