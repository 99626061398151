import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Input, Typography, Select, Form, Button } from "antd";
import "./verify.scss";
import { isNotEmptyString } from "../../utils/isEmptyString";


export default function Two({ handleCallApi, profile, isVerified }) {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      contact1Name: profile?.contact1Name,
      contact1PhoneNumber: profile?.contact1PhoneNumber,
      contact1Relationship: profile?.contact1Relationship,
      contact2Name: profile?.contact2Name,
      contact2PhoneNumber: profile?.contact2PhoneNumber,
      contact2Relationship: profile?.contact2Relationship,
      income: profile?.income,
      job: profile?.job,
      purpose: profile?.purpose,
    });
    if (isVerified) {
      setDisabled(true);
    }
  }, [profile]);

  const onFinish = async (values) => {
    let _contact = {
      ...values,
    };

    const verified = {};
    if (
      Object.values(_contact).length === 6 &&
      Object.values(_contact).indexOf("") < 0
    ) {
      verified.verifiedContactInfo = 1;
    }
    handleCallApi(_contact, 2, verified);
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="personal-information-container"
    >
      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
      >
        <p style={{ marginBottom: "4px", fontSize: "18px", fontWeight: "600" }}>
          Thông tin người liên hệ 1
        </p>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Họ tên
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập họ tên người liên hệ",
            },
          ]}
          name="contact1Name"
        >
          <Input
            size="large"
            placeholder="Nhập họ tên"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1Name)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Số điện thoại
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập số điện thoại người liên hệ",
            },
          ]}
          name="contact1PhoneNumber"
        >
          <Input
            size="large"
            placeholder="Nhập số điện thoại"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1PhoneNumber)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Quan hệ với người vay
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập mối quan hệ với người vay",
            },
          ]}
          name="contact1Relationship"
        >
          <Input
            size="large"
            placeholder="Nhập quan hệ với người vay"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact1Relationship)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "18px", fontWeight: "600" }}>
          Thông tin người liên hệ 2
        </p>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Họ tên
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập họ tên người liên hệ",
            },
          ]}
          name="contact2Name"
        >
          <Input
            size="large"
            placeholder="Nhập họ tên"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact2Name)}
          />
        </Form.Item>
        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Số điện thoại
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập số điện thoại người liên hệ",
            },
          ]}
          name="contact2PhoneNumber"
        >
          <Input
            size="large"
            placeholder="Nhập số điện thoại"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact2PhoneNumber)}
          />
        </Form.Item>

        <p style={{ marginBottom: "4px", fontSize: "16px" }}>
          <span style={{ color: "red" }}>*</span> Quan hệ với người vay
        </p>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Nhập mối quan hệ với người vay",
            },
          ]}
          name="contact2Relationship"
        >
          <Input
            size="large"
            placeholder="Nhập quan hệ với người vay"
            style={{
              borderRadius: 5,
              padding: 5,
              fontSize: 18,
            }}
            disabled={isNotEmptyString(profile?.contact2Relationship)}
          />
        </Form.Item>
        { isVerified !== 1 ? <motion.div
          whileTap={{ opacity: 0.4, scale: 0.97 }}
          style={{ display: "flex", justifyContent: "center" }}
          disabled={disabled}
        >
          <Button className="confirm-btn" htmlType="submit">
            <Typography.Text className="btn-title">Tiếp tục</Typography.Text>
          </Button>
        </motion.div> : '' }
      </Form>
    </motion.div>
  );
}
