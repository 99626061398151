import React, { useState, useEffect } from 'react';
import { Typography, Image, } from 'antd';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { Notification } from '../../components';
import home from '../../assets/banner.jpg';
import banner1 from '../../assets/banner1.jpg';
import banner2 from '../../assets/banner2.jpg';
import banner3 from '../../assets/banner3.jpg';
import banner4 from '../../assets/banner4.jpg';
import './Home.scss';
import { useSelector } from 'react-redux';

const arrayText = [
  '097***4 đã rút 35.000.000 đ',
  '033***8 đã rút 30.000.000 đ',
  '087***3 đã rút 45.000.000 đ',
  '033***1 đã rút 100.000.000 đ',
  '035***5 đã được duyệt hồ sơ',
  '033***5 đã rút 80.000.000 đ',
];

const questions = [
  {
    question: "Điều kiện vay là gì ?",
    answer: "Có quốc tịch Việt Nam và trong độ tuổi từ 18-55 tuổi, có địa chỉ thường trú và địa chỉ làm việc hiện tại trong lãnh thổ Việt Nam, có CMND/CCCD còn hiệu lực, có thu nhập ổn định. Có các giấy tờ như bảo hiểm xã hội hoặc bảo hiểm y tế, hợp đồng lao động ..."
  },
  {
    question: "Làm sao để đăng ký vay ?",
    answer: "Đăng ký khoản vay tại đây và liên hệ với nhân viên tư vấn để được hỗ trợ!"
  },
  {
    question: "Khi thẩm định hồ sơ ngân hàng có tiến hành thông báo và kiểm tra thông tin qua người thân như vợ hoặc chồng, bạn bè của người vay không ?",
    answer: "KHÔNG",
    bold: true
  }
]
export default function Home() {
  const history = useHistory();
  const { profile } = useSelector((state) => state._auth);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [token] = useState(localStorage.getItem("access_token"));

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndex < arrayText.length - 1)
        setCurrentIndex((prev) => prev + 1);
      else setCurrentIndex((prev) => 0);
    }, 1800);
    return () => clearInterval(interval);
  });
  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="container"
    >
      <div className="header-content">
        <div>
          <Typography.Text className="text-head">Xin chào, </Typography.Text>
          <br />
          <Typography.Text className="text-head">
            {profile?.kyc?.name || profile.phone}
          </Typography.Text>
        </div>
        <motion.div
          whileTap={{ scale: 0.9, opacity: 0.7 }}
          onClick={() => history.push('/notifications')}
        >
          <Notification />
        </motion.div>
      </div>

      {/* <div className="noti-text-container">
        <Typography.Text className="noti-text">
          {arrayText[currentIndex]}
        </Typography.Text>
      </div> 
      <Carousel autoplay autoplaySpeed={2800} className="carousel-container">
        <div>
          <div className="carousel slider2">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider3">
            <div className="sl-text-container"></div>
          </div>
        </div>
        <div>
          <div className="carousel slider4">
            <div className="sl-text-container"></div>
          </div>
        </div>
      </Carousel>*/}

      <div
        style={{ display: 'flex', justifyContent: 'center', width: "100%", marginTop: "4px" }}
        className='bannerTop'
      >
        <Image
          preview={false}
          src={home}
          width={'100%'}
          height={'100%'}
        />
      </div>

      <div className='list-image'>
        <img
          alt=""
          src={banner1}
        />
        <img
          alt=""
          src={banner2}
        />
        <img
          alt=""
          src={banner3}
        />
        <img
          alt=""
          src={banner4}
        />
      </div>

      <div className='question_answer'>
        {questions.map((item, index) =>
          <div className='question_answer_container'>
            <p className='question'>{index + 1}. {item.question}</p>
            <p className={`answer ${item?.bold ? "bold" : ""}`}>- {item.answer}</p>
          </div>)}

        <div className="note">
          * Liên hệ người thân chỉ dùng trong trường hợp vay thành công nhưng tới kỳ hạn đóng tiền người vay không thực hiện nghĩa vụ trả nợ và không thể liên lạc được. Lúc đó phía ngân hàng sẽ liên lạc với các liên hệ này để nhắc nhở và thông báo
        </div>
      </div>

      <div style={{ position: "fixed", right: 0, left: 0, bottom: "75px", textAlign: "center", margin: "0 4px", padding: "10px 12px", backgroundColor: "#FAD400", borderRadius: "8px", fontWeight: "600", fontSize: "16px", color: "white" }}
        onClick={() => {
          if (token) {
            if (profile?.verifiedBankInfo && profile?.verifiedContactInfo && profile?.verifiedPersonalInfo) {
              history.push("/vay")
            } else {
              history.push("/verify")
            }
          } else {
            history.push("/auth")
          }
        }}>
        Vay ngay
      </div>
    </motion.div>
  );
}